/* Import Google Font */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

html, body, #root, .App {
  height: 100%;
}

.App {
  text-align: center;
}

.App-header {
  background: linear-gradient(135deg, #ECECF9, #CADFFC, #C6C6EC);
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #515670;
  padding: 20px;
  box-sizing: border-box;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  opacity: 0.85;
  animation: float 6s ease-in-out infinite;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

h1 {
  font-size: 3rem;
  font-weight: 600;
  color: #002480;
  margin-bottom: 10px;
  text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.5);
}

.catchy-sentence {
  font-size: 1.2rem;
  color: #515670;
  margin-bottom: 30px;
}

.notify-button {
  display: inline-flex;
  align-items: center;
  padding: 15px 30px;
  background-color: #002480;
  color: #fff;
  font-size: 1.2rem;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.button-icon {
  margin-right: 10px;
  font-size: 1.5rem;
}

.notify-button:hover {
  background-color: #515670;
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 36, 128, 0.3);
}

.Modal {
  position: relative;
  background: #fff;
  padding: 40px 20px;
  border-radius: 10px;
  max-width: 400px;
  margin: 5% auto;
  outline: none;
  box-shadow: 0 5px 15px rgba(0, 36, 128, 0.2);
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(81, 86, 112, 0.8);
  z-index: 1000;
}

.close-button {
  position: absolute;
  top: 15px;
  right: 20px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  color: #515670;
  cursor: pointer;
}

.Modal h2 {
  margin: 0 0 20px;
  color: #002480;
  text-align: center;
}

.Modal form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Modal input {
  margin: 10px 0;
  padding: 12px 15px;
  width: 80%;
  font-size: 1rem;
  border: 1px solid #CADFFC;
  border-radius: 30px;
  outline: none;
}

.Modal input:focus {
  border-color: #002480;
}

.submit-button {
  margin-top: 20px;
  padding: 12px 30px;
  background-color: #002480;
  color: #fff;
  font-size: 1rem;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #515670;
}

@media (max-width: 768px) {
  h1 {
    font-size: 2.5rem;
  }

  .catchy-sentence {
    font-size: 1rem;
  }

  .notify-button {
    font-size: 1rem;
    padding: 12px 25px;
  }

  .App-logo {
    height: 30vmin;
  }
}
